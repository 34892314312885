import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, throwError, BehaviorSubject, Subscription } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as commonactions from 'src/app/store/common.actions';
import { AlertService } from '../alert/alert.service';
import { Store } from '@ngrx/store';

export interface MediaDriveVariables {
  accountid: string;
  channel: string;
  bspid: string;
  wabano: string;
  page: number | null;
  size: number | null;
  filetype: string | null;
  startdate: string | null;
  enddate: string | null;
}

export interface DateObject {
  from: NgbDate | null;
  to: NgbDate | null;
  action: string;
  requestfrom: string | null;
}

export interface RoleVariables {
  accountid: string;
  page: number;
  size: number;
  name: string;
  startdate: string;
  enddate: string;
  bspid: string;
  wabano: string;
  channel: string;
}

@Injectable({
  providedIn: 'root',
})
export class SharedService implements OnDestroy {
  datePickerObject!: BehaviorSubject<DateObject | null>;
  template_create_data$: BehaviorSubject<any>;
  addsample_data$: BehaviorSubject<any>;
  tempType: BehaviorSubject<any>;
  queuestatus$: BehaviorSubject<any>;
  selectedBA$: BehaviorSubject<any>;
  quillInstance: any = null;

  customDateAction: BehaviorSubject<any>;
  private subscription: Subscription | null = null;

  constructor(
    private http: HttpClient, 
    private alertMsg: AlertService,
    private readonly store: Store
  ) {
    this.datePickerObject = new BehaviorSubject<DateObject>(null);
    this.template_create_data$ = new BehaviorSubject<any>(null);
    this.addsample_data$ = new BehaviorSubject<any>(null);
    this.tempType = new BehaviorSubject<any>(null);
    this.queuestatus$ = new BehaviorSubject<any>(null);
    this.selectedBA$ = new BehaviorSubject<any>(null);
    this.customDateAction = new BehaviorSubject<any>(null);
  }
  account_dtls: any = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
    businessname: null,
  };
  channelCredentials(payload: any) {
    const url =
      environment.backendurl +
      `/api/channel-credentials/view-credentials/` +
      payload.account_id;
    let params = new HttpParams();
    if (payload.channel) {
      params = params.set('channel', payload.channel);
    }
    if (payload.waba_no) {
      params = params.set('waba_number', payload.waba_no);
    }
    if (payload.businessname) {
      params = params.set('business_name', payload.businessname);
    }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updatechannelCredentials(payload: any) {
    const url = environment.backendurl + `/api/channel-credentials/update`;
    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  syncchannelCredentials(payload: any) {
    const url = environment.backendurl + `/api/channel-credentials/syncdata`;
    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  fileUpload(
    file: File,
    accountid: string,
    mobileno: string,
    channel: string,
    createdby: string,
    isactive: boolean
  ) {
    const url = environment.backendurl + `/api/drive/upload`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('account_id', accountid);
    formData.append('mobile_number', mobileno);
    formData.append('channel', channel);
    formData.append('created_by', createdby);
    formData.append('is_active', isactive.toString());
    return this.http.post(url, formData).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  fileUrlUpload(
    Url: string,
    accountid: string,
    mobileno: string,
    channel: string,
    createdby: string
  ) {
    const url = environment.backendurl + `/api/drive/checkurl`;
    const payload = {
      url: Url,
      account_id: accountid,
      mobile_number: mobileno,
      channel: channel,
      created_by: createdby,
    };
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  // For Downloading sample CSV file using local/remote file URL
  downloadSampleFile(url: string, fileType: string, filename: string) {
    FileSaver.saveAs(url, filename);
  }

  // For Downloading All Types of Files from Media Drive
  downloadMediaFile(data: any, fileType: string, filename: string) {
    FileSaver.saveAs(data, filename);
  }

  // For Downloading CSV generated file from campaings and templates
  exportFile(data: any, fileType: string, filename: string) {
    const blob = new Blob([data], { type: fileType });
    FileSaver.saveAs(blob, filename);
  }

  exportToCsv(rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows
        .map((row: any) => {
          return keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell =
                cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(separator);
        })
        .join('\n');

    this.exportFile(csvContent, 'text/csv', 'templatedata');
  }

  fetchmediaServer(payload: MediaDriveVariables) {
    const url = environment.backendurl + `/api/drive/` + payload.accountid;
    let params = new HttpParams();
    if (payload.filetype) {
      params = params.set('file_type', payload.filetype);
    }
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }
    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deleteMediaServerData(payload: any) {
    const url = environment.backendurl + `/api/drive/delete`;
    return this.http.delete(url, { body: payload }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getAnalyticsData(
    accountid: string,
    wabano?: string,
    startdate?: string,
    enddate?: string,
    page?: number,
    size?: number,
    campaign_id?: string
  ) {
    const url =
      environment.backendurl + `/api/analytics/view-analytics/${accountid}`;

    let params = new HttpParams();
    if (wabano) {
      params = params.set('waba_number', wabano);
    }
    if (page) {
      params = params.set('page', page);
    }
    if (size) {
      params = params.set('size', size);
    }
    if (campaign_id) {
      params = params.set('campaign_id', campaign_id);
    }
    if (startdate) {
      params = params.set('start_date', startdate);
    }
    if (enddate) {
      params = params.set('end_date', enddate);
    }
    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  fetchWlbData() {
    const url = environment.backendurl;
    const modifiedUrl = url.replace('/backend', '') + `/static/config.json`;

    return this.http.get(modifiedUrl).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getWlbdatabyaccountid(accountid: string) {
    const url = environment.backendurl + `/api/whitelabel/` + accountid;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  createWlbData(payload: any, accountid: string) {
    const url = environment.backendurl + `/api/whitelabel/create`;
    const wlb_payload = {
      account_id: accountid,
      wlb_settings: payload,
    };

    return this.http.post(url, wlb_payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updateWlbData(payload: any, accountid: string) {
    const url = environment.backendurl + `/api/whitelabel/update/` + accountid;
    const wlb_payload = {
      account_id: accountid,
      wlb_settings: payload,
    };

    return this.http.put(url, wlb_payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  wlbUpload(file: File, accountid: string) {
    const url = environment.backendurl + `/api/whitelabel/upload`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('account_id', accountid);
    return this.http.post(url, formData).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getLicenseDetails(accountid: string) {
    const url =
      environment.backendurl + `/api/licenses/fetch-license/` + accountid;

    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  changeProductDefault(profile_id: string, payload: any) {
    const url = environment.backendurl + `/api/acls/abac/` + profile_id;

    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getchannelsList(data: any) {
    // const Channels_list = JSON.parse(data);
    const Channels_list = data;
    const keyWithLengthGreaterThanZero = [];

    for (const key in Channels_list) {
      if (
        Object.prototype.hasOwnProperty.call(Channels_list, key) &&
        Channels_list[key].length > 0
      ) {
        keyWithLengthGreaterThanZero.push(key);
      }
    }

    const channellists: any = [];
    if (keyWithLengthGreaterThanZero.length > 0) {
      keyWithLengthGreaterThanZero.forEach((t: any) => {
        const { bsp_name, waba_number, is_default } = Channels_list[t][0];
        channellists.push({
          bsp_name: bsp_name,
          waba_no: waba_number,
          is_default: is_default,
          channel: t,
        });
      });
    }

    return channellists;
  }

  getShortName = (fullName: any): string => {
    return fullName
      .split(' ')
      .map((n: any) => n[0])
      .join('');
  };

  
  validateUploadImage(data:any)
  {
    const mimeType = data[0].type;
    if (mimeType.match(/image\/*/) === null) {
      this.alertMsg.alertWarning('Warning', 'Only images are supported.');
      return;
    }

    const upload_file = data[0];
    // Create a FileReader to read the file
    const reader = new FileReader();

    // When the file is loaded
    reader.onload = (e: any) => {
      // Create a new Image object
      const img = new Image();
      
      // When the image is loaded
      img.onload = () => {
       // Access the height and width of the image
       const height = img.height;
       const width = img.width;


       if (width <= 500 && height <= 500) {
        this.store.dispatch(
          commonactions.initiateuploadFile({
            file: upload_file,
            accountid: this.account_dtls.accountid,
            mobileno: this.account_dtls.wabano,
            channel: this.account_dtls.channel,
            createdby: this.account_dtls.createdby,
            is_active: false
          })
        );
        } else {
          this.alertMsg.alertWarning(
            'Warning',
            'Image width and height should not be more than 500px X 500px'
          );
        }
      };
      // Set the source of the image to the file data
      img.src = e.target.result;
    };
    // Read the file as a data URL
    reader.readAsDataURL(upload_file);
  }

  subscribe(subscription: Subscription): void {
    if (!this.subscription) {
      this.subscription = subscription;
    }
  }
 
 
  unsubscribe(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
 
}
