import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/services/shared.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AlertService } from 'src/app/alert/alert.service';
import { Store } from '@ngrx/store';
import * as consentactions from 'src/app/customer/consent-management/store/consent.actions';
import { Subject, takeUntil, Subscription } from 'rxjs';
import { selectConsentDataList } from 'src/app/customer/consent-management/store/consent.selectors';
import { Consent } from 'src/app/appdata/consent.model';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { ChannelCredentials, RbacPolicies } from 'src/app/appdata/auth.model';
import {
  ConsentDataPayload,
  ConsentService,
} from 'src/app/services/consent.service';
import { skeleton_data } from '../../whatsapp-preview/whatsapp-preview.data';

@Component({
  selector: 'app-optin',
  templateUrl: './optin.component.html',
  styleUrls: ['./optin.component.scss'],
})
export class OptinComponent implements OnInit, OnDestroy {
  page = 1;
  pageSize = 10;
  totaloptinlist: number = 0;

  range!: FormGroup;
  file: File;
  searchPhNo: string = '';
  consentSource: any;
  allOptinData: Consent[] = [];
  permissions: RbacPolicies;
  channel_credentials: ChannelCredentials;
  templateDataSubscription: Subscription;
  isLoading: boolean = true;
  isFirst: boolean = true;
  skeletonData = skeleton_data;

  allSources = [
    { name: 'Web', value: 'web', icon: 'assets/img/web.svg' },
    { name: 'WhatsApp', value: 'whatsapp', icon: 'assets/img/whatsapp.svg' },
    { name: 'E-mail', value: 'email', icon: 'assets/img/email.svg' },
    {
      name: 'Physical Form',
      value: 'physical form',
      icon: 'assets/img/physical_form.svg',
    },
    { name: 'SMS', value: 'sms', icon: 'assets/img/sms.svg' },
    { name: 'Voice', value: 'voice', icon: 'assets/img/voice.svg' },
    {
      name: 'Missed Call',
      value: 'missed call',
      icon: 'assets/img/missed_call.svg',
    },
  ];
  queue_status: any = null;
  account_dtls: ConsentDataPayload = {
    account_id: null,
    bsp_id: null,
    waba_no: null,
    channel: null,
    bsp_name: null,
    createdby: null,
    mobileno: null,
    consent_status: 'optin',
    source: null,
    startdate: null,
    enddate: null,
    page: this.page,
    size: this.pageSize,
  };
  selectedSource: string | null = null;
  channelSub: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  selection = new SelectionModel<Consent>(true, []);

  constructor(
    private consentservice: ConsentService,
    private shareservice: SharedService,
    private alertMsg: AlertService,
    private store: Store,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.initProfileDataSubscription();
    this.initChannelDataSubscription();
    this.initConsentDataSubscription();
    this.initFormGroup();
    // this.shareservice.datePickerObject.next(null);
    this.shareservice.unsubscribe();
    this.shareservice.customDateAction.next(7);
    this.initDateFilterSubscription();
  }

  initProfileDataSubscription() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.loggedInUserDetails) {
          this.account_dtls.createdby = res.loggedInUserDetails.profile.id;
          this.permissions =
            res.loggedInUserDetails.profile.acl.rbac.rbac_policies;
        }
      });
  }

  initChannelDataSubscription() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value: any) => {
      if (!value) return;

      const { account_id, bsp_id, waba_number, channel_name, bsp_name } =
        value.channel_credentials;
      this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));

      this.account_dtls.account_id = account_id;
      this.account_dtls.bsp_id = bsp_id;
      this.account_dtls.waba_no = waba_number;
      this.account_dtls.channel = channel_name;
      this.account_dtls.bsp_name = bsp_name;
      this.channel_credentials = value.channel_credentials;
      this.isLoading = true;

      if (!this.isFirst) {
        this.store.dispatch(
          consentactions.getconsentAction.fetchconsentdata({
            payload: this.account_dtls,
          })
        );
      }

      this.isFirst = false;
    });
  }

  initConsentDataSubscription() {
    this.store
      .select(selectConsentDataList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (!res) return;
        this.selection.clear();

        this.modalService.dismissAll();
        this.file = null;
        this.queue_status = null;
        if (res.consentdata) {
          this.isLoading = false;
          if (res.consentdata.consents) {
            this.allOptinData = res.consentdata.consents;
            this.totaloptinlist = res.consentdata.total;
            this.consentservice.totalconsents.next(this.totaloptinlist);
          } else if (res.consentdata.statusCode === 404) {
            this.allOptinData = JSON.parse(JSON.stringify(this.allOptinData));
            this.allOptinData = [];
            this.totaloptinlist = 0;
            this.consentservice.totalconsents.next(this.totaloptinlist);
          }
        } else if (res.bulkuploadstatus) {
          const {
            statusCode,
            message,
            total_count,
            total_sent_count,
            queue_id,
          } = res.bulkuploadstatus;

          if (res.bulkuploadstatus.status_code === 200) {
            this.alertMsg.alertSuccess('Success', res.bulkuploadstatus.message);
          } 

          if (statusCode === 200) {
            if (total_count !== total_sent_count) {
              this.store.dispatch(
                consentactions.queuestatus.getqueuestatus({ id: queue_id })
              );
            }
          } else if (statusCode === 400) {
            this.isLoading = false;
            this.alertMsg.alertDanger('Error', message);
          } else if (statusCode === 404) {
            this.isLoading = false;
            this.alertMsg.alertWarning('Warning!', message);
            this.isLoading = true;
            this.store.dispatch(
              consentactions.getconsentAction.fetchconsentdata({
                payload: this.account_dtls,
              })
            );
          }
        } else if (res.queue_status) {
          if (res.queue_status.statusCode === 200) {
            this.shareservice.queuestatus$.next(res.queue_status.message);
            if (
              res.queue_status.message.total_received_count <
              res.queue_status.message.total_count
            ) {
              setTimeout(() => {
                this.store.dispatch(
                  consentactions.queuestatus.getqueuestatus({
                    id: res.bulkuploadstatus.queue_id,
                  })
                );
              }, 2000);
            } else {
              this.alertMsg.alertSuccess(
                'Success',
                'Data uploaded successfully'
              );
            }
          }
        } else if (res.updatedatastatus) {
          this.isLoading = false;
          this.alertMsg.alertSuccess(
            'Success',
            'Bulk Consent Update Initiated'
          );
          this.selection.clear();
          this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
          this.isLoading = true;
          this.store.dispatch(
            consentactions.getconsentAction.fetchconsentdata({
              payload: this.account_dtls,
            })
          );
        } else if (res.searchresults) {
          this.isLoading = false;
          if (res.searchresults.statusCode === 404) {
            this.alertMsg.alertDanger('Error', res.searchresults.message);

            // this.allOptinData = JSON.parse(JSON.stringify(this.allOptinData));
            this.allOptinData = [];
            this.allOptinData = res.searchresults.consents;
            this.totaloptinlist = res.searchresults.total;
            this.consentservice.totalconsents.next(this.totaloptinlist);
          }
        } else if (res.error && res.error.error && res.error.error.message) {
          this.isLoading = false;
          const dispErr = res.error.error.message;
          if (dispErr) {
            this.alertMsg.alertDanger('Error', dispErr);
          }
        } else if (res.error) {
          this.isLoading = false;
          this.allOptinData = [];
          this.totaloptinlist = 0;
          this.consentservice.totalconsents.next(this.totaloptinlist);
          const dispErr =
            res.error.statusCode === 500
              ? res.error.message
              : res.error.error.message;
          if (dispErr) {
            this.alertMsg.alertDanger('Error', dispErr);
          }
        }
      });
  }

  initDateFilterSubscription() {
    // this.templateDataSubscription 
    const datepicker = this.shareservice.datePickerObject.subscribe((value) => {
      if (!value) return;
      this.searchPhNo = '';
      const { from, to, action } = value;
      const start_date = from
        ? `${from.year}-${from.month}-${from.day}`
        : null;
      const end_date = to ? `${to.year}-${to.month}-${to.day}` : null;
      if (start_date && end_date) {
        this.fetchConsentDataWithDate(start_date, end_date);
      } else if (action === 'Date Cleared') {
        this.fetchConsentDataWithoutDate();
      }
    });

    this.shareservice.subscribe(datepicker);
  }

  fetchConsentDataWithDate(start_date: string, end_date: string) {
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    this.account_dtls.mobileno = null;
    this.account_dtls.startdate = start_date;
    this.account_dtls.enddate = end_date;
    this.account_dtls.page = 1;
    this.account_dtls.size = this.pageSize;
    this.page = 1;
    this.pageSize = 10;
    this.isLoading = true;
    this.store.dispatch(
      consentactions.getconsentAction.fetchconsentdata({
        payload: this.account_dtls,
      })
    );
  }

  fetchConsentDataWithoutDate() {
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    this.account_dtls.startdate = null;
    this.account_dtls.enddate = null;
    this.account_dtls.page = 1;
    this.account_dtls.size = this.pageSize;
    this.page = 1;
    this.pageSize = 10;
    this.isLoading = true;

    this.store.dispatch(
      consentactions.getconsentAction.fetchconsentdata({
        payload: this.account_dtls,
      })
    );
  }

  initFormGroup() {
    this.range = new FormGroup({
      start: new FormControl<Date | null>(null, [Validators.required]),
      end: new FormControl<Date | null>(null, [Validators.required]),
    });
  }

  initqueueStatusSubscription() {
    this.shareservice.queuestatus$.subscribe((value) => {
      if (value) {
        this.queue_status = value;
      } else {
        this.queue_status = null;
      }
    });
  }

  downloadSampleFile(action: string) {
    const url =
      'https://ocmp.zupiq.com/backend/api/drive/uploads/da5a9881-f858-49a5-ad3f-96d1e620f91d/1710931598845-508-Sample_consent_file.csv';
    const filename = 'consent_sample_file_ocmp.csv';
    this.shareservice.downloadSampleFile(url, action, filename);
  }

  onMobleChange(event: any) {
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    if (!event) {
      this.shareservice.datePickerObject.next(null);
      this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
      this.account_dtls.mobileno = null;
      this.account_dtls.startdate = null;
      this.account_dtls.enddate = null;
      this.isLoading = true;
      this.store.dispatch(
        consentactions.getconsentAction.fetchconsentdata({
          payload: this.account_dtls,
        })
      );
    } else {
      this.searchMobile(event);
    }
  }

  searchMobile(data: string) {
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    if (data && data.length > 2) {
      this.account_dtls.mobileno = data.trim();

      this.store.dispatch(
        consentactions.searchMobileNo.searchmobileno({
          payload: this.account_dtls,
        })
      );
    }
  }

  getSource(data: string) {
    this.consentSource = this.allSources.find((t) => t.value === data);
    return this.consentSource;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.allOptinData.length;
   //this.totaloptinlist;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.allOptinData);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Consent): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  onPageEvent(event: any) {
    this.page = event;
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    this.account_dtls.page = this.page;
    this.isLoading = true;
    this.store.dispatch(
      consentactions.getconsentAction.fetchconsentdata({
        payload: this.account_dtls,
      })
    );
  }

  openModal(content: TemplateRef<string>) {
    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      size: 'md',
      backdrop: 'static',
    });
  }

  onSourceSelect(data: string | null) {
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    this.account_dtls.source = data;
    this.account_dtls.page = 1;
    this.account_dtls.size = this.pageSize;
    this.page = 1;
    this.pageSize = 10;
    this.isLoading = true;
    this.store.dispatch(
      consentactions.getconsentAction.fetchconsentdata({
        payload: this.account_dtls,
      })
    );
  }

  onSelect(event: any) {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size / 1024 / 1024 > 2) {
        this.alertMsg.alertWarning(
          'Warning!',
          "You can't upload documents which are greater than 2MB"
        );
        return;
      } else {
        this.file = event.target.files[0];
      }
    }
  }

  onBulkFileUpload() {
    this.account_dtls = JSON.parse(JSON.stringify(this.account_dtls));
    this.store.dispatch(
      consentactions.initiatebulkupload.bulkupload({
        file: this.file,
        consent_status: this.account_dtls.consent_status,
        created: this.account_dtls.createdby,
        bsp: this.account_dtls.bsp_name,
        bspstatus: 'pending',
        channel: this.account_dtls.channel,
        accountid: this.account_dtls.account_id,
        wabano: this.account_dtls.waba_no,
        bspid: this.channel_credentials.id,
      })
    );
  }

  formatFileSize = (size: any) => {
    const kiloByte = 1024;
    const megaByte = kiloByte * 1024;
    const gigaByte = megaByte * 1024;

    if (size >= gigaByte) {
      return `${(size / gigaByte).toFixed(2)} GB`;
    } else if (size >= megaByte) {
      return `${(size / megaByte).toFixed(2)} MB`;
    } else if (size >= kiloByte) {
      return `${(size / kiloByte).toFixed(2)} KB`;
    } else {
      return `${size} bytes`;
    }
  };

  bulkUpdateData(action: string) {
    const updateList: any = [];
    if (action) {
      this.selection.selected.forEach((t) => {
        updateList.push({ id: t.id, consent_status: action });
      });
      this.store.dispatch(
        consentactions.initiatebulkupdate({ updatedata: updateList })
      );
    }
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.templateDataSubscription.unsubscribe();
    // this.shareservice.datePickerObject.next(null);
    this.selection.clear();
  }
}
