import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Subject, takeUntil, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { SelectionModel } from '@angular/cdk/collections';
import * as templateactions from 'src/app/customer/templates/store/template.actions';
import { Template } from 'src/app/appdata/template.model';
import { TemplateService, TemplateVariables } from 'src/app/services/template.service';
import { skeleton_data } from 'src/app/customer/whatsapp-preview/whatsapp-preview.data';
import { LoaderService } from 'src/app/services/loader.service';
import {
  ActiveStatus,
  languages,
  categories,
  status,
} from 'src/app/customer/templates/templates.data';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/alert/alert.service';
import { SharedService } from 'src/app/services/shared.service';
import { selectActionsData } from 'src/app/customer/templates/store/template.selectors';
import { Router } from '@angular/router';
import { selectProfilesDtlsData } from 'src/app/store/common.selectors';
import { AuthUserData, RbacPolicies } from 'src/app/appdata/auth.model';

@Component({
  selector: 'app-templatelist',
  templateUrl: './templatelist.component.html',
  styleUrls: ['./templatelist.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplatelistComponent implements OnInit, AfterViewInit, OnDestroy {
  mainTemplateData: any;
  viewTemplateData: any;
  editItem: any;
  deleteItem: any;
  listingTemplate: any;
  template_payload: any;


  addSampleTemplateData: TemplateRef<any> | null = null;
  previewTemplateData: TemplateRef<any> | null = null;
  tempBody_data: string = null;
  searchData: string = null;
  searchSource: string = '';
  allStatus: ActiveStatus[] = status;
  templatesList: Template[] = [];
  variableList: object[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  selection = new SelectionModel<Template>(true, []);
  
  stepperCompletionStatus: boolean = false;
  isFirst: boolean = true;
  isLoading: boolean = true;
  
  skeletonData = skeleton_data;
  allCategories = categories;
  template_languages = languages;

  channelSub: Subscription;
  templateDataSubscription: Subscription;
  permissions: RbacPolicies;
  userDetails: AuthUserData;

  totalTemplateList: number = 0;
  page = 1;
  pageSize = 10;

  filterCriteria: any = {
    temp_category: null,
    temp_type: null,
    temp_lang: null,
    temp_status: null,
  };
  templateTypes = ['None', 'Text', 'Media'];
  displayedColumns: string[] = [
    'name',
    'category',
    'type',
    'status',
    'created',
    'language',
    'reason',
  ];

  @ViewChild('showTemplates') private showTemplates!: TemplateRef<any>;
  @ViewChild('createTemplates') private createTemplates!: TemplateRef<any>;
  @ViewChild('noTemplates') private noTemplates!: TemplateRef<any>;
  @ViewChild('skeletonTemp') private skeletonTemplate!: TemplateRef<any>;
  @ViewChild('phoneNumberTemp') private phoneNumberTemplate!: TemplateRef<any>;
  @ViewChild('visitWebsiteTemp') private visitWebsiteTemplate!: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private shareservice: SharedService,
    private cd: ChangeDetectorRef,
    private alertMsg: AlertService,
    public loader: LoaderService,
    private router: Router,
    private readonly store: Store,
    private templateService: TemplateService
  ) {}

  temp_payload_var: TemplateVariables = {
    accountid: null,
    bspid: null,
    wabano: null,
    page: this.page,
    size: this.pageSize,
    category: null,
    type: null,
    status: null,
    template_lang: null,
    template_name: null,
    startdate: null,
    enddate: null,
  };

  subscribeToProfileData() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.loggedInUserDetails) {
          this.userDetails = res.loggedInUserDetails;
          this.permissions = this.userDetails.profile.acl.rbac.rbac_policies;
        }
      });
  }

  subscribeToBA() {
    this.channelSub = this.shareservice.selectedBA$.subscribe((value) => {
      if (value) {
        this.temp_payload_var = JSON.parse(
          JSON.stringify(this.temp_payload_var)
        );
        this.temp_payload_var.accountid = value.channel_credentials.account_id;
        this.temp_payload_var.bspid = value.channel_credentials.bsp_id;
        this.temp_payload_var.wabano = value.channel_credentials.waba_number;
        // this.loader.show();
        if (!this.isFirst) {
          this.store.dispatch(
            templateactions.fetchtemplateslist({ payload: this.temp_payload_var })
          );
        }
        this.isFirst = false;
      }
    });
  }

  subscribeToTemplateData() {
    this.store
      .select(selectActionsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.templateList) {
          this.templatesList = JSON.parse(
            JSON.stringify(res.templateList.data.templates)
          );

          // Precompute the timestamps
          const timestamps = this.templatesList.map((template) =>
            new Date(template.created_at).getTime()
          );

          // Sort the templatesList based on precomputed timestamps
          this.templatesList.sort(
            (a, b) =>
              timestamps[this.templatesList.indexOf(b)] -
              timestamps[this.templatesList.indexOf(a)]
          );

          this.isLoading = false;

          this.totalTemplateList = res.templateList.data.count;
          this.loader.hide();
          if (
            (this.temp_payload_var.startdate &&
              this.temp_payload_var.enddate) ||
            !this.checkFilteredItems()
          ) {
            this.mainTemplateData = {
              data:
                this.templatesList?.length === 0 ? null : this.templatesList,
              template: this.showTemplates,
            };
          }
        } else if (res.deletetemplateres) {
          if (res.deletetemplateres.status_code === 200) {
            this.modalService.dismissAll();
            this.alertMsg.alertSuccess(
              'Success',
              res.deletetemplateres.message
            );
            this.store.dispatch(
              templateactions.fetchtemplateslist({
                payload: this.temp_payload_var,
              })
            );
          }
        } else if (res.error) {
          this.loader.hide();
          this.isLoading = false;
          this.temp_payload_var = JSON.parse(
            JSON.stringify(this.temp_payload_var)
          );
          this.temp_payload_var.template_name = null;
          const dispErr = res.error.error?.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      });
  }

  subscribeToDatePicker() {
    this.shareservice.customDateAction.next(7);
    this.templateDataSubscription =
      this.shareservice.datePickerObject.subscribe((value) => {
        if (value) {
          if (value.from && value.to) {
            const fromDate: NgbDateStruct = value.from;
            const toDate: NgbDateStruct = value.to;

            this.temp_payload_var = JSON.parse(
              JSON.stringify(this.temp_payload_var)
            );

            this.temp_payload_var.page = null;
            this.temp_payload_var.size = null;
            this.temp_payload_var.startdate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
            this.temp_payload_var.enddate = `${toDate.year}-${toDate.month}-${toDate.day}`;

            this.store.dispatch(
              templateactions.fetchtemplateslist({
                payload: this.temp_payload_var,
              })
            );
          }
          if (value.action === 'Date Cleared') {
            this.temp_payload_var = JSON.parse(
              JSON.stringify(this.temp_payload_var)
            );
            this.temp_payload_var.startdate = null;
            this.temp_payload_var.enddate = null;
            // this.temp_payload_var.page = 1;
            // this.temp_payload_var.size = this.pageSize;
            // this.page = 1;
            // this.pageSize = this.pageSize;
            this.store.dispatch(
              templateactions.fetchtemplateslist({
                payload: this.temp_payload_var,
              })
            );
          }
        }
      });
  }

  ngOnInit(): void {
    this.subscribeToProfileData();
    this.subscribeToBA();
    this.subscribeToTemplateData();
    this.shareservice.datePickerObject.next(null);
    this.subscribeToDatePicker();
  }

  getLangData(data: any) {
    const langs = JSON.parse(data);
    const langsarray: any = [];
    langs.forEach((m: string) => {
      langsarray.push(languages.find((t) => t.langCode === m).langName);
    });
    return langsarray.toString();
  }

  getContentData(data: string): string {
    try {
      const content = JSON.parse(data);
      return content.reason === 'NONE' ? 'N/A' : content.reason;
    } catch (error) {
      return 'N/A';
    }
  }

  ngAfterViewInit(): void {
    this.mainTemplateData = {
      data: this.templatesList?.length === 0 ? null : this.templatesList,
      template: this.showTemplates,
    };

    this.cd.detectChanges();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.templatesList?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.templatesList);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Template): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.account_id + 1
    }`;
  }

  getBtnsNames(data: any) {
    const btn_names: any = [];
    data.forEach((t: any) => {
      btn_names.push(t.type_of_action);
    });
    return btn_names.toString();
  }

  openModal(content: TemplateRef<string>, data: string, item?: any) {
    const sizes: Record<string, string> = {
      viewTemplate: 'lg',
      addSample: 'xl',
      deleteTemplate: 'md',
    };

    if (data === 'viewTemplate') {
      this.editItem = item;
      this.store.dispatch(
        templateactions.templatePreviewData({
          data: JSON.parse(this.editItem.content),
        })
      );
    } else if (data === 'deleteTemplate') {
      this.deleteItem = item;
    }

    const size = sizes[data] || 'md';

    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      backdrop: 'static',
      size,
    });
  }

  onCreateTemplate() {
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'templates',
      'create',
    ]);
  }

  openEditTemplate(item: any) {
    this.router.navigate([
      'customer',
      this.userDetails.profile.id,
      'templates',
      item.id,
      'edit',
    ]);
  }

  getActionTypeTemplate(actionTypeId: number | null): TemplateRef<any> | null {
    switch (actionTypeId) {
      case 1:
        return this.phoneNumberTemplate;
      case 2:
        return this.visitWebsiteTemplate;
      default:
        return null;
    }
  }

  refreshTemplatesList() {
    this.isLoading = true;
    this.templatesList = [];
    this.totalTemplateList = 0;
    this.store.dispatch(
      templateactions.fetchtemplateslist({ payload: this.temp_payload_var })
    );
  }

  clearFilter(action: string) {
    this.temp_payload_var = JSON.parse(JSON.stringify(this.temp_payload_var));

    switch (action) {
      case 'category':
        this.temp_payload_var.category = null;
        this.filterCriteria.temp_category = null;
        break;
      case 'status':
        this.temp_payload_var.status = null;
        this.filterCriteria.temp_status = null;
        break;
      case 'type':
        this.temp_payload_var.type = null;
        this.filterCriteria.temp_type = null;
        break;
      case 'lang':
        this.temp_payload_var.template_lang = null;
        this.filterCriteria.temp_lang = null;
        break;
      case 'clearfilter':
        this.temp_payload_var.category = null;
        this.temp_payload_var.status = null;
        this.temp_payload_var.type = null;
        this.temp_payload_var.template_lang = null;
        this.filterCriteria.temp_category = null;
        this.filterCriteria.temp_status = null;
        this.filterCriteria.temp_type = null;
        this.filterCriteria.temp_lang = null;
        break;
      default:
        break;
    }

    this.checkFilteredItems();
    this.store.dispatch(
      templateactions.fetchtemplateslist({ payload: this.temp_payload_var })
    );
  }

  checkFilteredItems() {
    return Object.values(this.filterCriteria).every((value) => value === null);
  }

  deleteTemplate() {
    this.store.dispatch(
      templateactions.deletetemplate({ data: this.deleteItem.id })
    );
  }

  searchName(data: string) {
    if (data && data.length > 2) {
      this.temp_payload_var = JSON.parse(JSON.stringify(this.temp_payload_var));
      this.temp_payload_var.template_name = data.trim();
      this.store.dispatch(
        templateactions.fetchtemplateslist({ payload: this.temp_payload_var })
      );
    }
  }

  onSearchNameChange(event: any) {
    if (!event) {
      this.temp_payload_var = JSON.parse(JSON.stringify(this.temp_payload_var));
      this.temp_payload_var.template_name = null;
      this.selection.clear();
      this.store.dispatch(
        templateactions.fetchtemplateslist({ payload: this.temp_payload_var })
      );
    } else {
      this.searchName(event);
    }
  }

  applyFilter() {
    this.temp_payload_var = JSON.parse(JSON.stringify(this.temp_payload_var));
    if (this.filterCriteria.temp_category) {
      this.temp_payload_var.category =
        this.filterCriteria.temp_category.toLowerCase();
    }
    if (this.filterCriteria.temp_type) {
      this.temp_payload_var.type = this.filterCriteria.temp_type.toLowerCase();
    }
    if (this.filterCriteria.temp_status) {
      this.temp_payload_var.status =
        this.filterCriteria.temp_status.toLowerCase();
    }

    if (this.filterCriteria.temp_lang) {
      this.temp_payload_var.template_lang =
        this.filterCriteria.temp_lang.langCode.toLowerCase();
    }
    this.selection.clear();
    // this.isLoading = true;
    this.store.dispatch(
      templateactions.fetchtemplateslist({ payload: this.temp_payload_var })
    );

    this.checkFilteredItems();
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  downloadData(data: any) {
    const csvData: any = data;
    let headers =
      'Name,Category,Type,Language,Created On,Status,Rejected Reason\n';
    const timezone = ' (GMT+5:30 IST)';

    csvData.forEach((item: any) => {
      const name = item.template_name;
      const category = item.category;
      const type = item.type;
      const language = JSON.parse(item.languages)[0];
      const createdOn = item.created_at ? new Date(item.created_at).toLocaleString() + timezone : 'N/A';
      const status = item.status;
      const rejectedReason = item.bsp_metadata
        ? JSON.parse(item.bsp_metadata).reason
        : '';

      headers += `"${name}","${category}","${type}","${language}","${createdOn}","${status}","${rejectedReason}"\n`;
    });
    this.shareservice.exportFile(
      headers,
      'text/csv;charset=utf-8;',
      'templatedata'
    );
    this.selection.clear();
  }

  // To do download all the available templates
  downloadAllTemp()
  {
    const temp_payload_var = {
      ...JSON.parse(JSON.stringify(this.temp_payload_var)),
    };
  
    temp_payload_var.size = this.totalTemplateList;
    temp_payload_var.page = 1;
    this.templateService.fetchtemplates(temp_payload_var).subscribe({
      next: (response) => {
        if (response.status_code === 200) {
          this.downloadData(response.data.templates);
        }
      },
      error: (error) => {
        if (error.message) {
          const dispErr = error.message;
          this.alertMsg.alertDanger('Error', dispErr);
        }
      },
    });
  }
  

  onPageEvent(event: any) {
    this.page = event;
    this.selection.clear();
    this.temp_payload_var = JSON.parse(JSON.stringify(this.temp_payload_var));

    this.temp_payload_var.page = this.page;
    this.temp_payload_var.size = this.pageSize;

    this.store.dispatch(
      templateactions.fetchtemplateslist({ payload: this.temp_payload_var })
    );
  }

  closeViewModal() {
    this.modalService.dismissAll();
    this.store.dispatch(templateactions.resettemplatePreviewData());
    this.selection.clear();
  }

  ngOnDestroy() {
    this.channelSub.unsubscribe();
    this.store.dispatch(templateactions.resetTemplateData());
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.templateDataSubscription.unsubscribe();
    this.shareservice.datePickerObject.next(null);
    this.shareservice.customDateAction.next(null);
  }
}
