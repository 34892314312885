<!-- Loader -->
<div class="center">
  <span class="loader" *ngIf="loader.visibility | async"></span>
</div>
<div class="spinner-overlay" *ngIf="loader.visibility | async"></div>
<!-- END Loader -->

<ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>

<!-- Empty Template -->
<ng-template #noCampaigns>
  <div class="card p-3">
    <div class="top_header_options">
      <h3 class="main_title">Campaigns</h3>
    </div>
  </div>
  <div class="card p-3 mt-2">
    <!-- template Content -->
    <div class="empty_template text-center my-4">
      <img class="w-30" src="assets/img/no_data.svg" alt="Setup Campaign" />
      <h4 class="mb-3">No Campaigns Here</h4>
      <button
        type="button"
        class="btn btn-lg btn-gradient btn-primary"
        (click)="setupNewCampaign()"
      >
        <em class="bi bi-plus-circle-fill me-2"></em> Set Campaign
      </button>
    </div>
    <!-- END template Content -->
  </div>
</ng-template>
<!-- END Empty Template -->

<!-- Show Campaigns -->
<ng-template #showCampaigns>
  <div class="card card-sticky">
    <div class="top_header_options">
      <div class="title">
        <h3 class="main_title">
          Campaigns
          <span
            class="badge badge-primary rounded-pill"
            matTooltip="Total Campaigns Count"
            >{{ totalCampaigns }}</span
          >
        </h3>
        <p class="d-none">
          <small>(<b>MSISDN</b> : {{ channel_credentails.waba_number }}, <b>WABA ID</b>: {{ channel_credentails.waba_id }})</small>
        </p>
      </div>

      <div class="guides">
        <!-- Setup Campaign Button -->
        <button
          class="btn btn-primary"
          (click)="setupNewCampaign()"
          matTooltip="Setup Campaign"
        >
          <em class="bi bi-plus-circle-fill me-2"></em> Setup Campaign
        </button>
        <!-- END Setup Campaign Button -->
      </div>
    </div>
  </div>

  <div
    class="alert alert-dismissible alert-soft-dark fade mt-3 show"
    role="alert"
  >
    <h5 class="main_title">Quick Guide</h5>
    <ul class="list-pointer mb-0 ml-0">
      <li class="list-pointer-item">
        Note: Campaigns Report typically takes 2 hours to update.
      </li>
      <li class="list-pointer-item">
        Tap on the campaigns to see detailed analytics.
      </li>
      <li class="list-pointer-item">
        All times mentioned below are in IST (India Standard Time, GMT+5:30).
      </li>
    </ul>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>

  <div class="card mt-2">
    <div class="card-body p-0">
      <!-- Header Options -->
      <div class="row justify-content-between">
        <div class="col-4">
          <!-- SearchBar -->
          <div class="input-group input-group-merge mb-0">
            <div class="input-group-prepend input-group-text">
              <em class="bi bi-search"></em>
            </div>
            <input
              type="search"
              class="form-control"
              placeholder="Search By Name"
              [(ngModel)]="searchData"
              (ngModelChange)="onSearchNameChange($event)"
              (keyup.enter)="searchName(searchData)"
              [ngClass]="searchData?.length < 3 && searchData?.length > 0 ? 'invalid-input': ''"
            />
          </div>
          <!-- END SearchBar -->
        </div>

        <div class="col-8 justify-content-end pr-2 d-flex align-items-center">
          <ul class="list-inline list-inline-flex mb-0">
            <!-- Download Data Option -->
            <li class="list-inline-item" *ngIf="campaignsList?.length > 0">
              <button
                type="button"
                class="btn btn-icon btn-default"
                matTooltip="Download all campaigns to CSV"
                (click)="downloadData()"
              >
                <em class="bi bi-download"></em>
              </button>
            </li>
            <!-- End Download Data Option -->

            <!-- Global Refresh Option -->
            <li class="list-inline-item">
              <button
                type="button"
                class="btn btn-icon btn-default"
                matTooltip="Global refresh to get all campaigns status"
                (click)="refreshCampaignsList()"
                *ngIf="campaignsList?.length > 0"
              >
                <em class="bi bi-arrow-repeat"></em>
              </button>
            </li>
            <!-- END Global Refresh Option -->

            <!-- Filters -->
            <li class="list-inline-item">
              <div
                ngbDropdown
                class="d-inline-block filter-dropdown"
                #myDrop="ngbDropdown"
              >
                <button
                  type="button"
                  class="btn btn-default"
                  id="CampaignfiltersDropDown"
                  ngbDropdownToggle
                >
                  <i class="bi bi-funnel me-1"></i>
                  Filters
                </button>
                <div
                  class="dropdown-menu"
                  ngbDropdownMenu
                  aria-labelledby="CampaignfiltersDropDown"
                >
                  <div class="card p-0 shadow-none">
                    <!-- Type Ng-select -->
                    <div class="card-header">
                      <h4 class="title">Filters</h4>
                    </div>
                    <div
                      class="card-body py-2 px-3"
                      (click)="stopPropagation($event)"
                      (keydown)="stopPropagation($event)"
                    >
                      <!-- Campaign Category Selection -->
                      <div class="form-group">
                        <div class="input-label"
                          >Campaign Category</div
                        >
                        <ng-select
                          [items]="campaignCategory"
                          bindLabel="name"
                          bindValue="name"
                          id="campaignCategory"
                          [(ngModel)]="filterCriteria.campaign_category"
                          [placeholder]="'-- Select --'"
                          [clearable]="false"
                          [searchable]="false"
                        >
                        </ng-select>
                      </div>
                      <!-- END Campaign Category Selection -->
                      
                      <!-- Campaign Type Selection -->
                      <div class="form-group">
                        <div class="input-label"
                          >Campaign Type</div
                        >
                        <ng-select
                          [items]="campaignType"
                          bindLabel="name"
                          bindValue="name"
                          id="campaignType"
                          [(ngModel)]="filterCriteria.campaign_type"
                          [placeholder]="'-- Select --'"
                          [clearable]="false"
                          [searchable]="false"
                        >
                        </ng-select>
                      </div>
                      <!-- END Campaign Type Selection -->

                      <!-- Campaign Tags -->
                      <div class="form-group">
                        <label for="campaignTags" class="input-label"
                          >Tags</label
                        >
                        <input
                          class="form-control"
                          id="campaignTags"
                          type="text"
                          [(ngModel)]="filterCriteria.campaign_tag"
                          placeholder="Type tag name"
                        />
                      </div>
                      <!-- END Campaign Tags -->

                      <!-- Campaign Created Profiles -->
                      <div
                        class="form-group"
                        *ngIf="userDetails.account.account_type === 'Admin'"
                      >
                        <div class="input-label"
                          >Profile (Only Admins)</div
                        >
                        <ng-select
                          [items]="campaignProfile"
                          id="campaignProfile"
                          bindLabel="name"
                          bindValue="id"
                          [(ngModel)]="filterCriteria.campaign_profile"
                          [placeholder]="'-- Select --'"
                          [clearable]="false"
                          [searchable]="false"
                        >
                        </ng-select>
                      </div>
                      <!-- END Campaign Created Profiles -->

                      <!-- Campaign Status -->
                      <div class="form-group">
                        <div class="input-label"
                          >Status</div
                        >
                        <ng-select
                          [items]="campaignStatus"
                          id="campaignStatus"
                          [(ngModel)]="filterCriteria.campaign_status"
                          [placeholder]="'-- Select --'"
                          [clearable]="false"
                          [searchable]="false"
                        >
                        </ng-select>
                      </div>
                      <!-- END Campaign Status -->
                    </div>

                    <!-- Apply Filters Button -->
                    <div class="card-footer">
                      <button
                        type="button"
                        class="btn btn-block btn-gradient"
                        (click)="
                          applyFilter();
                          $event.stopPropagation();
                          myDrop.close()
                        "
                        *ngIf="
                          filterCriteria.campaign_category ||
                          filterCriteria.campaign_type ||
                          filterCriteria.campaign_tag ||
                          filterCriteria.campaign_profile ||
                          filterCriteria.campaign_status
                        "
                      >
                        Apply
                      </button>
                    </div>
                    <!-- END Apply Filters Button -->
                  </div>
                </div>
              </div>
            </li>
            <!-- END Filters -->

            <!-- Calender Date Range Selector -->
            <li class="list-inline-item">
              <app-datepicker [requestfrom]="'campaignListDetails'"></app-datepicker>
            </li>
            <!-- END Calender Date Range Selector -->
          </ul>
        </div>
      </div>
      <!-- END Header Options -->
    </div>

    <!-- Filter Selected Values -->
    <div
      class="selected_filters mt-2 p-2 d-flex align-items-center justify-content-between"
      *ngIf="!checkFilteredItems()"
    >
      <!-- Selected Filter Options -->
      <div class="selected_items">
        <mat-chip-listbox aria-label="Selected Type and Status">
          <mat-chip *ngIf="filterCriteria.campaign_category">
            Type: <b>{{ filterCriteria.campaign_category | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('category')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>

          <mat-chip *ngIf="filterCriteria.campaign_type">
            Type: <b>{{ filterCriteria.campaign_type | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('type')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>

          <mat-chip *ngIf="filterCriteria.campaign_tag">
            Type: <b>{{ filterCriteria.campaign_tag }}</b>
            <button matChipRemove (click)="clearFilter('tag')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>

          <mat-chip *ngIf="filterCriteria.campaign_profile">
            Type: <b>{{ filterCriteria.campaign_profile | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('profile')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>

          <mat-chip *ngIf="filterCriteria.campaign_status">
            Status: <b>{{ filterCriteria.campaign_status | titlecase }}</b>
            <button matChipRemove (click)="clearFilter('status')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-listbox>
      </div>
      <!-- END Selected Filter Options -->

      <!-- Clear Filters Option -->
      <div class="clear_filters">
        <mat-chip-listbox aria-label="Clear selected filters">
          <mat-chip (click)="clearFilter('clearfilter')" (keydown)="clearFilter('clearfilter')"> Clear All </mat-chip>
        </mat-chip-listbox>
      </div>
      <!-- END Clear Filters Option -->
    </div>
    <!-- END Filter Selected Values -->

    <!-- Campaigns Listing Table -->
    <div class="table-wrapper mt-2">
      <div class="table-container mb-0">
        <mat-table [dataSource]="campaignsList">
          <ng-container matColumnDef="name" sticky>
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell
              *matCellDef="let element"
              class="d-flex justify-content-between"
            >
              <div
                class="fw-bold text-truncate"
                [ngClass]="element.status === 'completed' || element.status === 'inprogress' || element.status === 'paused' ? 'pointer comp_name w-90':'w-95'"
                (click)="viewCampaign(element)"
                (keydown)="viewCampaign(element)"
                matTooltip="{{ element.name }}"
                matTooltipPosition="above"
              >
                {{ element.name }}
              </div>
              <div class="d-flex">
                <!-- View Campaign Analytics Button -->
                <button
                  class="btn btn-option"
                  matTooltip="View campaign analytics"
                  (click)="viewCampaign(element)"
                  *ngIf="
                    element.status &&
                    (element.status === 'completed' ||
                      element.status === 'inprogress' ||
                      element.status === 'paused'
                    )
                  "
                >
                  <em class="bi bi-graph-up-arrow"></em>
                </button>
                <!-- END View Campaign Analytics Button -->

                <!-- More Options -->
                <div
                  class="card-unfold"
                  matTooltip="More options"
                  *ngIf="
                    element.status &&
                    (element?.status.toLowerCase() === 'ongoing' ||
                      element?.status.toLowerCase() === 'scheduled' ||
                      element?.status.toLowerCase() === 'draft' ||
                      element?.status.toLowerCase() === 'paused' ||
                      element?.status.toLowerCase() === 'inprogress' && (element.is_preprocessed && !element.is_start_campaign))"
                >
                  <button
                    class="btn btn-option"
                    [matMenuTriggerFor]="menu"
                    aria-label="Campaign Action Options"
                  >
                    <em class="bi bi-three-dots-vertical"></em>
                  </button>
                  <mat-menu #menu="matMenu">
                    <div
                      class="dropdown-menu-sm dropdown-menu-right animated slideInup"
                    >
                      <!-- Run Now Option -->
                      <a
                        class="dropdown-item"
                        matTooltip="Run campaign"
                        matTooltipPosition="above"
                        *ngIf="
                          element.status && element?.status.toLowerCase() === 'inprogress' &&
                          (element.is_preprocessed && !element.is_start_campaign)
                        "
                        (click)="runCampaign(element)"
                      >
                        <i class="bi-play-fill text-primary"></i>
                        Run now
                      </a>
                      <!-- END Run Now Option -->

                      <!-- Re-schedule Option -->
                      <a
                        class="dropdown-item"
                        matTooltip="Re-schedule campaign"
                        matTooltipPosition="above"
                        *ngIf="
                          element.status &&
                          element.status.toLowerCase() === 'scheduled'
                        "
                        (click)="editCampaign(element)"
                      >
                        <i class="bi-calendar-fill text-primary"></i>
                        Re-schedule
                      </a>
                      <!-- END Re-schedule Option -->

                      <!-- Pause Option -->
                      <a
                        class="dropdown-item"
                        matTooltip="Pause campaign"
                        matTooltipPosition="above"
                        *ngIf="
                          element.status &&
                          element.status.toLowerCase() === 'ongoing'
                        "
                        (click)="PauseCampaign(element)"
                      >
                        <i class="bi-pause-fill text-primary"></i> Pause
                      </a>
                      <!-- END Pause Option -->

                      <!-- Resume Option -->
                      <a
                        class="dropdown-item"
                        matTooltip="Resume campaign"
                        matTooltipPosition="above"
                        *ngIf="
                          element.status &&
                          element.status.toLowerCase() === 'paused'
                        "
                        (click)="ResumeCampaign(element)"
                      >
                        <i class="bi-play-fill text-primary"></i> Resume
                      </a>
                      <!-- END Resume Option -->

                      <!-- Edit Option -->
                      <a
                        class="dropdown-item"
                        matTooltip="Edit campaign"
                        matTooltipPosition="right"
                        *ngIf="
                          element.status &&
                          element?.status.toLowerCase() === 'draft'
                        "
                        (click)="editCampaign(element)"
                      >
                        <i class="bi-pencil-fill text-primary"></i> Edit
                      </a>
                      <!-- End Edit Option -->

                      <!-- Delete Option -->
                      <a
                        class="dropdown-item text-danger"
                        matTooltip="Delete campaign"
                        matTooltipPosition="right"
                        *ngIf="
                          (element.status &&
                            element?.status.toLowerCase() === 'scheduled') ||
                          element?.status.toLowerCase() === 'draft'
                        "
                        (click)="
                          openModal(
                            deleteCampaignModal,
                            'deleteCampaign',
                            element
                          )
                        "
                      >
                        <i class="bi-trash3-fill"></i> Delete
                      </a>
                      <!-- End Delete Option -->
                    </div>
                  </mat-menu>
                </div>
                <!-- END More Options -->
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.category | titlecase }}
            </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="campaigntype">
            <mat-header-cell *matHeaderCellDef> Campaign Type </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.is_bulk_campaign ? 'Bulk':'Single' | titlecase }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="template">
            <mat-header-cell *matHeaderCellDef> Template </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="text-truncate" [matTooltip]="getTemplate(element.template_payload).template_name" matTooltipPosition="above">
                {{
                  element.template_payload
                    ? getTemplate(element.template_payload).template_name
                    : "N/A"
                }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div
                class="status_indicator"
                [ngClass]="element.status ? element?.status.toLowerCase() : ''"
              >
                <em class="status_icon"></em>
                <span class="status_text">
                  <ng-container *ngIf="element.status; else nodata">
                    {{ element.status | titlecase }}
                    <em class="bi-info-circle ms-2 text-muted"
                      [matTooltip]="getCampaignTooltipStatus(element?.status.toLowerCase())"
                    ></em>
                  </ng-container>
                  <ng-template #nodata> N/A </ng-template>
                </span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div *ngIf="element.tags; else notags">
                <!-- {{ element.tags | tags : ", " }} -->
                <div class="d-flex align-items-center">
                  <ng-container
                    *ngIf="(element.tags | tags).length > 0; else notags"
                  >
                    <span *ngIf="(element.tags | tags).length > 0">{{
                      (element.tags | tags)[0].init
                    }}</span>
                  </ng-container>
                  <span
                    *ngIf="(element.tags | tags).length > 1"
                    class="ms-1 badge badge-pill badge-primary"
                    [matTooltip]="(element.tags | tags)[1].tooltip"
                  >
                    <i class="bi-plus"></i>
                    {{ (element.tags | tags)[1].count }}
                  </span>
                </div> 
              </div>
              <ng-template #notags>
                <td>N/A</td>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="starttime">
            <mat-header-cell *matHeaderCellDef> Start Time </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div *ngIf="element.started_on; else nostartsat">
                {{ element.started_on | date : "dd/MM/yyyy, h:mm a" }}
              </div>
              <ng-template #nostartsat>
                <td>N/A</td>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="endtime">
            <mat-header-cell *matHeaderCellDef> End Time </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div *ngIf="element.ended_on; else noended">
                {{ element.ended_on | date : "dd/MM/yyyy, h:mm a" }}
              </div>
              <ng-template #noended>
                <td>N/A</td>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="scheduled">
            <mat-header-cell *matHeaderCellDef>
              Scheduled Time
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div *ngIf="element.scheduled_at; else noschedule">
                {{ element.scheduled_at | date : "dd/MM/yyyy, h:mm a" }}
              </div>
              <ng-template #noschedule>
                <td>N/A</td>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions" [stickyEnd]="true">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element" class="text-center pr-0">
              <!-- More Options -->
              <div
                class="card-unfold"
                *ngIf="
                  element.status &&
                  (element?.status.toLowerCase() === 'ongoing' ||
                    element?.status.toLowerCase() === 'scheduled' ||
                    element?.status.toLowerCase() === 'draft' ||
                    element?.status.toLowerCase() === 'paused')
                "
              >
                <button
                  class="btn btn-option"
                  [matMenuTriggerFor]="menu"
                  aria-label="Campaign Action Options"
                >
                  <em class="bi bi-three-dots-vertical"></em>
                </button>
                <mat-menu #menu="matMenu">
                  <div
                    class="dropdown-menu-sm dropdown-menu-right animated slideInup"
                  >
                    <a
                      class="dropdown-item"
                      *ngIf="
                        element.status &&
                        (element.status.toLowerCase() === 'scheduled' ||
                          element.status.toLowerCase() === 'draft')
                      "
                    >
                      <i class="bi-calendar-fill text-primary"></i> Re-schedule
                    </a>
                    <a
                      class="dropdown-item"
                      *ngIf="
                        element.status &&
                        element.status.toLowerCase() === 'ongoing'
                      "
                      (click)="PauseCampaign(element)"
                    >
                      <i class="bi-pause-fill text-primary"></i> Pause
                    </a>
                    <a
                      class="dropdown-item"
                      *ngIf="
                        element.status &&
                        element.status.toLowerCase() === 'paused'
                      "
                      (click)="ResumeCampaign(element)"
                    >
                      <i class="bi-play-fill text-primary"></i> Resume
                    </a>
                    <a
                      class="dropdown-item"
                      *ngIf="
                        permissions.campagin_management.can_create &&
                        element?.scheduled
                      "
                    >
                      <i class="bi-calendar-fill text-primary"></i>
                      Reschedule
                    </a>
                    <a
                      class="dropdown-item"
                      *ngIf="
                        element.status &&
                        element?.status.toLowerCase() === 'draft'
                      "
                      (click)="editCampaign(element)"
                    >
                      <i class="bi-pencil-fill text-primary"></i> Edit
                    </a>
                    <a
                      class="dropdown-item text-danger"
                      *ngIf="
                        (element.status &&
                          element?.status.toLowerCase() === 'scheduled') ||
                        element?.status.toLowerCase() === 'draft'
                      "
                      (click)="
                        openModal(
                          deleteCampaignModal,
                          'deleteCampaign',
                          element
                        )
                      "
                    >
                      <i class="bi-trash3-fill"></i> Delete
                    </a>
                  </div>
                </mat-menu>
              </div>
              <!-- END More Options -->
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          <!-- Row shown when there is no matching data. -->
          <ng-container *matNoDataRow>
            <div *ngIf="isLoading">
              <ngx-skeleton-loader
                class="skeleton_loader"
                *ngFor="let item of skeletonData"
                [theme]="item"
              ></ngx-skeleton-loader>
            </div>

            <!-- Opt-Filters Empty Image -->
            <div
              class="d-flex flex-column align-items-center text-center my-4"
              *ngIf="!isLoading"
            >
              <img
                class="w-20"
                src="assets/img/no_data.svg"
                alt="No data found matching the filters"
              />
              <p>No data found matching the filters</p>
            </div>
            <!-- End Filters Empty Image -->
          </ng-container>
        </mat-table>
      </div>
    </div>
    <!-- END Campaigns Listing Table -->

    <!-- Pagination -->
    <div class="pagination-wrapper mt-3" *ngIf="totalCampaigns > pageSize">
      <ngb-pagination
        class="ngb-pagination"
        [collectionSize]="totalCampaigns"
        [(page)]="page"
        [pageSize]="pageSize"
        aria-label="pagination"
        (pageChange)="onPageEvent($event)"
        [rotate]="true"
        [maxSize]="3"
        [ellipses]="true"
        [boundaryLinks]="false"
      >
        <ng-template ngbPaginationPrevious>
          <i class="bi-chevron-left"></i>
          Previous</ng-template
        >
        <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
        <ng-template ngbPaginationNext>
          Next
          <i class="bi-chevron-right"></i
        ></ng-template>
      </ngb-pagination>
    </div>
    <!-- END Pagination -->

    <!-- No Data -->
    <div
      class="empty_template text-center my-4"
      *ngIf="campaignsList?.length === 0 && !checkFilteredItems()"
    >
      <img class="w-30" src="assets/img/no_data.svg" alt="Setup Campaign" />
      <h4 class="mb-3">No Campaigns Records Found</h4>
    </div>
    <!-- END No Data-->
  </div>
</ng-template>
<!-- END Show Campaigns -->

<!-- Delete Campaign Modal -->
<ng-template #deleteCampaignModal let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-center">
    <h3 class="modal-title">Delete Campaign</h3>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <h4>Are you sure want to delete the selected Campaign/s?</h4>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-light" (click)="d('Cross click')">Cancel</button>
    <button class="btn btn-outline-danger" (click)="onDeleteCampaign()">
      Delete
    </button>
  </div>
</ng-template>
<!-- END Delete Campaign Modal -->

<!-- Show Data Waiting Skelton Template -->
<ng-template #datawaiting>
  <ngx-skeleton-loader
    class="skeleton_loader"
    *ngFor="let item of skeletonData"
    [theme]="item"
  ></ngx-skeleton-loader>
</ng-template>
<!-- End Show Data Waiting Skelton Template -->
