import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { WhiteLabelGuard } from './services/whitelabel.gaurd';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [WhiteLabelGuard] },
  {
    path: 'reset-password/:id',
    component: ResetpasswordComponent,
  },
  {
    path: 'customer',
    loadChildren: () =>
      import('./customer/customer-routing.module').then(
        (m) => m.CustomerRoutingModule
      ),
  },
  {
    path: 'cc',
    loadChildren: () =>
      import('./control-center/control-center-routing.module').then(
        (m) => m.ControlCenterRoutingModule
      ),
  },
  {
    path: 'sa',
    loadChildren: () =>
      import('./super-admin/super-admin-routing.module').then(
        (m) => m.SuperAdminRoutingModule
      ),
  },
  {
    path: 'page-not-found',
    component: PagenotfoundComponent,
    children: [{ path: 'noaccess', component: PagenotfoundComponent }],
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
