<!-- Opt-In Header Options -->
<div class="row justify-content-between align-items-start my-3">
  <div class="col-4">
    <!-- Search by mobile number input -->
    <div class="input-group input-group-merge mb-0">
      <div class="input-group-prepend input-group-text">
        <em class="bi bi-search" (click)="searchMobile(searchPhNo)"></em>
      </div>
      <input
        type="search"
        class="form-control"
        tabindex="0"
        placeholder="Search by mobile number"
        [(ngModel)]="searchPhNo"
        (keyup.enter)="searchMobile(searchPhNo)"
        (ngModelChange)="onMobleChange($event)"
        [ngClass]="searchPhNo?.length < 3 && searchPhNo?.length > 0 ? 'invalid-input': ''"
      />
    </div>
    <!-- END SearchBar -->
  </div>

  <div class="col-8 pr-2 d-flex align-items-center justify-content-end">
    <ul class="list-inline list-inline-flex mb-0">
      <!-- Selected Values Count -->
      <li class="list-inline-item" *ngIf="selection.hasValue()">
        <span class="fw-bold">
          Selected : {{ selection.selected.length }}
        </span>
      </li>
      <!-- END Selected Values Count -->

      <!-- Moveto Option -->
      <li class="list-inline-item" *ngIf="selection.hasValue()">
        <div ngbDropdown placement="bottom-end">
          <button
            class="btn btn-outline-primary"
            ngbDropdownToggle
            *ngIf="permissions.consent_management.can_update"
          >
            <i class="bi bi-folder-symlink me-2"></i>
            Move to
          </button>
          <div class="p-2" ngbDropdownMenu>
            <button ngbDropdownItem (click)="bulkUpdateData('blocklist')">
              Blocklist
            </button>
          </div>
        </div>
      </li>
      <!-- END Moveto Option -->

      <!-- Source Filter -->
      <li class="list-inline-item">
        <ng-select
          [items]="allSources"
          bindLabel="name"
          bindValue="value"
          [(ngModel)]="selectedSource"
          (ngModelChange)="onSourceSelect(selectedSource)"
          [closeOnSelect]="true"
          [clearable]="true"
          [clearOnBackspace]="true"
          [searchable]="true"
          [tabIndex]="2"
          placeholder="Source"
        >
        </ng-select>
      </li>
      <!-- END Source Filter -->

      <!-- Calender Date Range Selector -->
      <li class="list-inline-item">
        <app-datepicker></app-datepicker>
      </li>
      <!-- END Calender Date Range Selector -->
    </ul>
  </div>
</div>
<!-- END Opt-In Header Options -->

<!-- Show Consent Listing -->
<div class="show_consent_listing" *ngIf="totaloptinlist > 0; else nodata">
  <!-- Consent Opt-In Listing -->
  <div class="table-wrapper">
    <table class="table table-striped" aria-describedby="Consent Opt-In">
      <thead class="table-head">
        <tr class="table-row">
          <th scope="col" *ngIf="permissions.consent_management.can_update">
            <mat-checkbox
              class="list-checkboxes m-0"
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>

            <span>Mobile Number</span>
          </th>
          <th scope="col">Added On</th>
          <th scope="col">Source</th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr class="table-row" *ngFor="let item of allOptinData">
          <td *ngIf="permissions.consent_management.can_update">
            <mat-checkbox
              class="list-checkboxes m-0"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(item) : null"
              [checked]="selection.isSelected(item)"
              [aria-label]="checkboxLabel(item)"
            >
              <span class="fw-bold pointer">{{ item.mobile }}</span>
            </mat-checkbox>
          </td>
          <td class="col-3">
            {{ item.created_at | date : "short" }}
          </td>
          <td>
            <div
              class="source d-inline-flex align-items-center justify-content-start gap-1"
            >
              <ng-container *ngIf="getSource(item.source); else noSource">
                <img
                  class="img-fluid"
                  [src]="consentSource.icon"
                  alt="{{ consentSource.name }}"
                />
                {{ consentSource.name }}
              </ng-container>
              <ng-template #noSource>N/A</ng-template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- END Consent Opt-In Listing -->

  <!-- Pagination -->
  <div class="pagination-wrapper" *ngIf="totaloptinlist > pageSize">
    <ngb-pagination
      class="ngb-pagination"
      [collectionSize]="totaloptinlist"
      [(page)]="page"
      [pageSize]="pageSize"
      aria-label="pagination"
      (pageChange)="onPageEvent($event)"
      [rotate]="true"
      [maxSize]="3"
      [ellipses]="true"
      [boundaryLinks]="false"
    >
      <ng-template ngbPaginationPrevious>
        <i class="bi-chevron-left"></i>
        Previous</ng-template
      >
      <ng-template ngbPaginationNumber let-p>{{ p }}</ng-template>
      <ng-template ngbPaginationNext>
        Next
        <i class="bi-chevron-right"></i
      ></ng-template>
    </ngb-pagination>
  </div>
  <!-- END Pagination -->
</div>
<!-- END Show Consent Listing -->


<ng-template #nodata>
  <div *ngIf="isLoading">
    <ngx-skeleton-loader
      class="skeleton_loader"
      *ngFor="let item of skeletonData"
      [theme]="item"
    ></ngx-skeleton-loader>
  </div>
  <!-- Opt-In Empty Image -->
  <div class="align-items-center text-center mb-4" *ngIf="!isLoading">
    <img class="w-25" src="assets/img/no_data.svg" alt="No Opt-In data found" />
    <p>No Opt-In data found</p>
  </div>
  <!-- End Opt-In Empty Image -->
</ng-template>
